@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700&family=Montserrat:wght@400;500;700&display=swap");

body {
  margin: 0;
  font-family: "Inter", "Montserrat", "Roboto";
}

html {
  margin: 0;
}

@font-face {
  font-family: "bebasneuepro-regular";
  src: url("../src/assets/fonts/bebasneuepro-regular-webfont.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "bebasneuepro-bold";
  src: url("../src/assets/fonts/bebasneuepro-bold-webfont.woff2")
    format("woff2");
  font-weight: normal;
  font-style: normal;
}

/* COLOR PALETTE */

:root {
  --white: #ffffff;
  --gray: #e6e8e3;
  --primary: #aecf49;
  --primary-dark: #91b22d;
  --secondary: rgba(28, 47, 62);
  --secondary-light: rgba(28, 47, 62, 0.8);
  --secondary-hover: #2b3d4c;
  --icon-primary: #44546f;
  --icon-secondary: #000000;
  --input-border: #758195;
}

.white {
  color: var(--white);
}

.gray {
  color: var(--gray);
}

.green {
  color: var(--primary);
}

.blinker {
  margin-left: 5px;
  border-right: 0.05em solid;
  animation: blinker 1s steps(1) infinite;
}

@keyframes blinker {
  50% {
    border-color: transparent;
  }
}

.secondary {
  color: var(--secondary);
}

h1 {
  font-size: clamp(40px, 4vw, 56px);
  color: var(--gray);
  margin: 0;
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
}

h2 {
  font-size: clamp(32px, 5vw, 48px);
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
  margin: 0;
  color: var(--secondary);
}

h3 {
  font-size: clamp(28px, 1vw, 40px);
  color: var(--white);
  font-weight: 600;
  margin: 0;
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
}

h4 {
  font-size: clamp(24px, 1vw, 32px);
  color: var(--white);
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";

  margin: 0;
}

h5 {
  color: var(--secondary-light);
  font-size: 16px;
  font-weight: 600;
  padding: 8px 0;
  margin: 0;
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
}

h6 {
  color: var(--secondary-light);
  font-size: 14px;
  font-weight: 400;
  padding: 10px 16px;
  margin: 0;
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
}

p {
  font-size: clamp(16px, 2vw, 20px);
  font-weight: 400;
  font-family: "Montserrat", "Geist Sans", "Roboto";
  margin: 0;
  color: var(--secondary);
  line-height: 34px;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-family: "Montserrat", "Geist Sans", "Roboto";
}

a {
  text-decoration: none;
  font-family: "Montserrat", "Geist Sans", "Roboto";
}

a,
a:visited,
a:hover,
a:active {
  color: inherit;
}
li {
  font-size: clamp(16px, 2vw, 20px);
  line-height: 34px;
  color: var(--secondary);
}

.caret {
  color: var(--secondary);
}

form {
  border-radius: 0 0 16px 16px;
  width: 100%;
}

input {
  height: 46px;
  border: 1px solid var(--input-border);
  color: var(--gray);
  border-radius: 8px;
  padding: 0 16px;
  color: var(--secondary);
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-size: 16px;
}

label {
  font-weight: 600;
  font-size: 16px;
  color: var(--icon-primary);
  margin: 15px 0 8px;
}

textarea {
  border: 1px solid var(--input-border);
  color: var(--gray);
  border-radius: 8px;
  padding: 16px;
  height: 240px;
  resize: none;
  color: var(--secondary);
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-size: 16px;
}

button {
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-weight: 700;
}

.cta-button {
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--primary);
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  max-width: fit-content;
}

.cta-button:hover,
.cta-button-large:hover {
  background-color: var(--primary-dark);
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: var(--primary-dark);
  transition: background-color 0.3s ease;
}

.cta-button-large:hover,
.cta-button-dropdown:hover {
  background-color: var(--primary-dark);
  transition: background-color 0.3s ease;
}

.cta-button-large {
  padding: 20px 24px;
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--primary);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 25px;
  max-width: fit-content;
}

.cta-button-large-careers {
  height: 84px;
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--primary);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin: 30px 0;
  padding: 24px 32px;
  max-width: fit-content;
}

.cta-button-large-careers:hover {
  background-color: var(--primary-dark);
  transition: background-color 0.3s ease;
}

@media (max-width: 450px) {
  .cta-button-large-careers {
    font-size: 16px;
  }
}

.cta-button-large-white {
  padding: 20px 24px;
  font-size: 24px;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--white);
  border-radius: 8px;
  border: none;
  cursor: pointer;
  margin-top: 30px;
  max-width: fit-content;
}

.cta-button-large-white:hover {
  background-color: var(--gray);
  transition: background-color 0.3s ease;
}

.cta-button-dropdown {
  font-size: 14px;
  font-weight: 600;
  width: 90%;
  color: var(--secondary);
  background-color: var(--primary);
  padding: 20px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  /* margin: 20px; */
}

.cta-button-dropdown:hover {
  background-color: var(--primary-dark);
  transition: background-color 0.2s ease;
}

.cta-button-secondary {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary);
  background-color: var(--secondary);
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  max-width: fit-content;
}

.cta-button-secondary-large {
  display: flex;
  align-items: center;
  font-size: 24px;
  font-weight: 600;
  color: var(--primary);
  background-color: var(--secondary);
  padding: 20px 24px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  max-width: fit-content;
}

.cta-button-secondary-large:hover {
  background-color: #1d2d49;
  transition: background-color 0.3s ease;
}

.cta-button-white {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--white);
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  max-width: fit-content;
}

.cta-button-white:hover {
  background-color: var(--gray);
  transition: background-color 0.3s ease;
}

.bold {
  font-weight: bold;
}

figure {
  margin: 0;
  padding: 0;
}

/* .plusminus {
  position: relative;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 2px;

  &.active {
    &:before {
      transform: translatey(-50%) rotate(-90deg);
      opacity: 0;
    }
    &:after {
      transform: translatey(-50%) rotate(0);
    }
  }

  &:before,
  &:after {
    content: "";
    display: block;
    background-color: var(--secondary);
    position: absolute;
    top: 50%;
    left: 0;
    transition: 0.35s;
    width: 100%;
    height: 5px;
    border-radius: 2px;
  }

  &:before {
    transform: translatey(-50%);
  }

  &:after {
    transform: translatey(-50%) rotate(90deg);
  }
} */

.caret-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 20px;
}

.caret {
  transition: transform 0.35s;
}

.caret.active {
  transform: rotate(180deg);
}
