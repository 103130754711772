/* NAVBAR */

.navbar-wrapper {
  width: 100%;
  height: 64px;
  background-color: var(--white);
  /* box-shadow: 0 0 10px 0; */
  box-shadow: 0px 2px 6px 2px rgba(9,30,66,0.14);
  position: fixed;
  z-index: 100;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1600px;
  height: 64px;
  margin: 0 auto;
  padding: 0 40px;
}

.innaxys-logo {
  width: 350px;
  margin: 0;
  padding: 0;
}

.nav-options-container {
  height: 100%;
}

.nav-options {
  display: flex;
  height: 96%;
}

.nav-option {
  display: flex;
  justify-content: center;
  list-style: none;
  text-decoration: none;
  height: 100%;
  padding: 0 40px 0 22px;
  cursor: pointer;
}

.active-nav {
  border-bottom: 3px solid var(--primary-dark) !important;
}

.nav-option:hover {
  border-bottom: 3px solid var(--gray);
}

.nav-item-container {
  display: flex;
  align-items: center;
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
  font-size: 20px;
  color: var(--secondary);
  gap: 8px;
  width: 60px;
}

.nav-option:hover .nav-caret {
  transform: rotate(180deg);
  transition: transform 0.3s ease;
}

.nav-caret {
  display: flex;
  align-items: center;
  margin-bottom: 3px;
}

.nav-right-side {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 350px;
}

.language-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nav-icon {
  cursor: pointer;
  margin-top: 1px;
  margin: 0 10px;
  color: var(--secondary);
}

.hamburger-menu {
  display: none;
}

@media (max-width: 1000px) {
  .nav-button {
    display: none;
  }
  .hamburger-menu {
    display: block;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }
}

@media (max-width: 900px) {
  .nav-options {
    display: none;
  }
}

/* DROPDOWN NAVBAR */

.desktop-nav-dropdown-container {
  background-color: #fff;
  padding: 16px;
  position: absolute;
  margin-top: 65px;
  border: 1px solid var(--gray);
  border-radius: 8px;
  box-shadow: 0px 2px 7px 3px rgba(9,30,66,0.14);
  display: flex;
  font-family: "Montserrat";
  color: var(--secondary);
  overflow: hidden;
  gap: 20px;
}

.desktop-nav-dropdown {
  display: flex;
  gap: 20px;
  flex-direction: column;
}

.desktop-nav-dropdown:has(span) {
  flex-direction: row;
}

.desktop-dropdown-nav-option span {
  font-weight: 700;
  font-size: 16px;
}

.desktop-dropdown-nav-option p {
  line-height: 20px;
}

.nav-solution-overview {
  display: flex;
  align-items: center;
  width: 180px;
  justify-content: space-between;
  margin-left: 20px;
  font-weight: 700;
}

.desktop-nav-dropdown p {
  font-size: 16px;
}

.active-dropdown-item {
  background-color: var(--primary);
  border-radius: 8px;
  padding: 16px;
  transition: background-color 0.3s ease;
}

.active-dropdown-item:hover {
  background-color: var(--primary) !important;
  border-radius: 8px;
  padding: 16px;
  transition: background-color 0.3s ease;
}

.desktop-dropdown-nav-option {
  padding: 16px;
  max-width: 270px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  gap: 10px;
}

.desktop-dropdown-nav-option div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.desktop-dropdown-nav-option:hover {
  background-color: var(--gray);
  padding: 16px;
  display: flex;
  flex-direction: column;
}

.dropdown-nav-container {
  height: 100vh;
  width: 450px;
  right: 0;
  position: absolute;
  background-color: var(--white);
  z-index: 20;
}

.dropdown-nav-more-options {
  display: flex;
  flex-direction: column;
  padding: 0px 16px;
  color: var(--secondary);
  gap: 20px;
}

.dropdown-nav-more-options a {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 8px;
  padding: 16px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.dropdown-nav-more-options p {
  font-size: 14px;
}

.dropdown-nav-more-options a:hover {
  background-color: var(--gray);
}

.dropdown-nav-more-options span {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
}

.dropdown-nav-option-wrapper {
  display: flex;
  align-items: center;
  padding: 24px 30px;
  cursor: pointer;
}

.dropdown-nav-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.overlay.visible {
  display: block;
}

@media screen and (max-width: 500px) {
  .dropdown-nav-container {
    width: 100vw;
  }

  .overlay.visible {
    display: none;
  }
}

/* LANGUAGE MODAL */

.lang-modal {
  display: flex;
}

.language-button {
  background: none;
  border: none;
  cursor: pointer;
}

@media (max-width: 500px) {
  .lang-modal {
    flex-direction: column;
  }

  .innaxys-logo {
    width: 150px;
  }

  .nav-right-side {
    width: 150px;
  }
}

.language-dropdown {
  background-color: #fff;
  padding: 10px 0;
  border: 1px solid var(--gray);
  border-radius: 8px;
  box-shadow: 0px 2px 6px 0px #000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  margin-top: 40px;
  margin-left: 60px;
}

.language-button {
  padding: 10px 20px;
  font-family: "Montserrat";
  color: var(--secondary);
  font-size: 16px;
}

.language-button:hover {
  background-color: var(--gray);
}

/* LANDING HERO SECTION */

.landing-container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 640px;
  background-color: var(--secondary);
  overflow: hidden;
}

.landing-section {
  width: 100%;
  display: flex;
  justify-content: center;
}

.landing-wrapper {
  max-width: 1600px;
  padding-left: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.landing-content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 850px;
}

.landing-content h1 {
  max-width: 700px;
}

.landing-content p {
  max-width: 700px;
  font-size: 16px;
  line-height: 24px;
}

.landing-image {
  display: flex;
  justify-content: flex-end;
  /* filter: drop-shadow(-6px 0px 10px #000000); */
}

.landing-image img {
  height: 100%;
  clip-path: polygon(3% 0, 100% 0, 100% 100%, 0% 100%);
}

/* LANDING CONTEXT */

.typewriter-breakline {
  display: none;
}

.landing-information-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
}

.landing-information-wrapper {
  display: flex;
  flex-direction: column;
  gap: 80px;
  max-width: 968px;
  margin: 80px 0 120px;
  color: var(--secondary);
}

.landing-information-wrapper div {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.liw-gap {
  margin-top: 15px;
}

@media (max-width: 1100px) {
  .landing-image {
    margin-right: -100px;
  }
}

@media (max-width: 900px) {
  .landing-content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .landing-content p {
    text-align: center;
  }

  .landing-wrapper {
    align-items: center;
    padding: 16px;
    text-align: center;
  }

  .landing-header {
    text-align: center;
    padding: 0 16px;
  }

  .landing-image {
    display: none;
  }

  .landing-information-container {
    padding: 16px;
  }

  .landing-information-wrapper {
    margin: 60px 0;
  }
}

@media (max-width: 500px) {
  .typewriter-breakline {
    display: block;
  }
}

/* STATS */

.stats-container {
  padding: 64px;
  background-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.stat-bar {
  display: flex;
  justify-content: space-around;
  background-color: var(--secondary);
  border-radius: 16px;
  padding: 32px 0;
  width: 1366px;
  /* filter: drop-shadow(0px 0px 10px #000000); */
}

.stat-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
}

.stat-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-container h4 {
  color: var(--white);
  font-family: "Montserrat", "Geist Sans", "Roboto";
  margin: 0;
  font-weight: 400;
}

.stat-container span {
  color: var(--primary);
  font-size: 40px;
  font-weight: bold;
  margin: 0;
}

@media (max-width: 700px) {
  .stat-container {
    flex-direction: column;
    width: 250px;
    gap: 32px;
  }
}

/* LANDING PRODUCTS */

.landing-products-container {
  display: flex;
  justify-content: center;
  padding: 64px;
  background-color: var(--gray);
}

.product-card-wrapper {
  display: flex;
  max-width: 1366px;
}

@media (max-width: 1300px) {
  .landing-products-container {
    padding: 30px;
  }

  .product-card-wrapper {
    flex-direction: column;
    width: 100%;
  }
}

/* PRODUCT CARD */

.product-card-container {
  background-color: #fff;
  margin: 16px;
  max-width: 418px;
  border-radius: 12px;
  overflow: hidden;
}

.product-card-image-container img {
  width: 100%;
}

.product-card-text-container {
  padding: 32px;
}

.product-card-header {
  font-size: 32px;
  font-weight: 600;
  color: #2c3e5d;
  margin-top: 10px;
}

.product-card-text {
  font-size: 24px;
  font-weight: 400;
  color: var(--secondary);
}

.product-card-button {
  background-color: white;
  color: #212121;
  border: none;
  padding: 6px 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}

@media (max-width: 1300px) {
  .product-card-container {
    display: flex;
    max-width: 100%;
  }

  .product-card-image-container {
    width: 1500px;
  }

  .product-card-image {
    object-fit: cover;
    height: 100%;
  }
}

@media (max-width: 600px) {
  .product-card-container {
    flex-direction: column;
  }

  .product-card-image-container {
    max-width: 100%;
  }
}

/* TRUSTED BY SECTION */

.trusted-by-container {
  width: 100%;
  position: relative;
}

.trusted-by-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray);
  width: 100%;
  padding: 30px 0;
}

.trusted-logo-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.trusted-logo {
  height: 50px;
  margin: 30px 40px;
}

@media (max-width: 600px) {
  .trusted-logo {
    height: 32px;
    margin: 30px 10px;
    max-width: 280px;
  }
}

/* PRODUCTS */

.products-container {
  display: flex;
  justify-content: center;
  padding-top: 64px;
  background-color: var(--secondary);
  max-height: 800px;
}

.products-content-container {
  display: flex;
  justify-content: flex-start;
  z-index: 1;
  width: 1600px;
}

.products-content {
  max-width: 600px;
  margin: 200px 32px;
}

.products-content {
  color: var(--white);
}

.products-image {
  position: absolute;
  right: 0;
  height: 800px;
  clip-path: polygon(46% 0, 100% 0, 100% 100%, 32% 100%);
}

.product-features-container {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 10;
  position: relative;
}

.product-feature {
  display: flex;
  align-items: center;
  width: 1300px;
}

.product-features-image img {
  border-radius: 12px;
  width: 540px;
  height: 540px;
}

/* INDUSTRIES */

.industry-container {
  width: 100%;
  background-color: var(--secondary);
}

.industry-wrapper {
  display: flex;
  align-items: center;
}

.industry-content {
  left: 0;
  max-width: 1200px;
  margin: 150px 5%;
  z-index: 20;
}

.industry-header {
  color: #ffffff;
  font-weight: 700;
  font-size: 58px;
}

.industry-paragraph {
  color: #ffffff;
  font-weight: 400;
  font-size: 32px;
}

.cta-button-secondary,
i {
  display: flex;
  align-items: center;
}

.industry-hero {
  right: 0;
  top: 64px;
  position: absolute;
  margin: 0;
  width: 60%;
  height: 800px;
}

.industry-hero img {
  width: 100%;
  height: auto;
  object-fit: cover;
  position: absolute;
  height: 800px;
  right: 0;
}

.industry-hero::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(9, 30, 66, 1), rgba(0, 0, 0, 0));
  z-index: 1;
}

@media (max-width: 678px) {
  .industry-content {
    margin-top: 150px;
  }
  .industry-hero img {
    /* Use opacity to keep the same container */
    opacity: 0;
  }
}

/* PRODUCTS */

/* DYTASK */

.dytask-content-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 10;
  padding: 80px 64px;
  background-color: var(--white);
}

.dytask-content-wrapper {
  max-width: 986px;
}

.dytask-content-wrapper p {
  margin: 20px 0;
}

.dytask-features-container {
  background-color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 80px;
  position: relative;
  z-index: 20;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  max-width: 1400px;
  gap: 64px;
}

.product-feature-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray);
  max-width: 320px;
  gap: 20px;
}

.product-feature-wrapper p {
  font-size: 20px;
  text-align: center;
  font-weight: 700;
}

.feature-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  width: 120px;
  height: 120px;
  border-radius: 60px;
}

.product-feature-list li {
  list-style-type: disc;
  margin: 10px 25px;
}

@media (max-width: 768px) {
  .dytask-content-container,
  .dytask-features-container {
    padding: 48px 16px;
  }
}

/* INDUSTRY FEATURES */

.industry-features-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 64px;
  position: relative;
}

.industry-features-header {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  max-width: 1290px;
}

.industry-features-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 1600px;
  margin: 0;
}

.industry-feature-container {
  margin: 20px;
  max-width: 400px;
}

.industry-feature-icon {
  color: var(--primary-dark);
}

.industry-features-header h4 {
  font-size: 32px;
  font-weight: 600;
  color: #2c3e5d;
  width: 100%;
}

/* DISCOVER */

.discover-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 64px;
  background-color: var(--primary);
  gap: 32px;
}

.discover-container h2 {
  color: var(--secondary);
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 0;
}

.discover-container p {
  font-size: 24px;
  font-weight: 400;
}

@media (max-width: 950px) {
  .discover-container {
    text-align: center;
    padding: 64px 16px;
  }
  .discover-container p {
    font-size: 18px;
  }
}

/* BOOK DEMO */

.bookdemo-container {
  display: flex;
  justify-content: center;
  padding: 100px;
  background-color: var(--secondary);
}

.bookdemo-wrapper {
  display: flex;
  justify-content: space-between;
  max-width: 1600px;
}

.bookdemo-text {
  display: flex;
  flex-direction: column;
  margin-right: 32px;
}

.bookdemo-header {
  color: #fff;
}

.bookdemo-subheading {
  color: var(--white);
  margin: 20px 0;
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-size: 24px;
  font-weight: 700;
}

.bookdemo-fact {
  display: flex;
  align-items: center;
  color: var(--gray);
  font-size: 20px;
  margin: 10px 0;
}

.bookdemo-fact i {
  margin-right: 20px;
}

.bookdemo-fact i {
  color: var(--primary);
}

@media (max-width: 1380px) {
  .bookdemo-wrapper {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

  .bookdemo-text {
    max-width: 700px;
    margin: 0 0 50px 0;
  }
  .bookdemo-container {
    padding: 100px 40px;
  }
}

@media (max-width: 768px) {
  .book-demo-form-container  {
    width: 90%;
  }
  .bookdemo-container {
    padding: 24px 16px 100px;
  }
}

/* RESOURCES */

.resources-hub-container {
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resources-hub-wrapper {
  display: flex;
  width: 1366px;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  padding: 80px 64px;
}

.option-container {
  border: 1px solid var(--gray);
  padding: 20px;
  border-radius: 16px;
  display: flex;
  width: 100%;
  max-width: 400px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

.resource-card-header {
  color: var(--secondary);
  font-family: "bebasneuepro-bold", "Bebas Neue", "Roboto";
  font-style: normal;
  font-weight: 700;
  margin-top: 0;
  font-size: clamp(32px, 5vw, 48px);
}

.resource-card-list {
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: var(--secondary);
  list-style: none;
}

.resources-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: var(--secondary);
}

.resources-path {
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-weight: 400;
  font-size: 20px;
}

.resources-list-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .resources-hub-wrapper {
    padding: 64px 16px 0;
  }
}

.resources-container {
  padding-top: 54px;
}

.resources-header-container {
  background-color: var(--secondary);
}

.resources-wrapper {
  max-width: 1366px;
  margin: 0 auto;
  color: #fff;
  padding: 64px 16px;
}

.resources-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 64px;
  gap: 32px;
}

.resources-contents-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 968px;
}

.resources-contents-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.resources-contents-list {
  display: flex;
  flex-direction: column;
  font-weight: bold;
  gap: 20px;
  color: var(--secondary);
  margin-bottom: 50px;
}

.resources-contents-list li:hover {
  text-decoration: underline;
  cursor: pointer;
}

.resources-body-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 968px;
}

.resources-body-container  h2 {
  margin-top: 24px;
}

.resources-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.resources-list {
  display: flex;
  flex-direction: column;
  list-style: disc;
  margin-left: 30px;
  gap: 20px;
}

.back-container {
  background-color: #fff;
  padding: 5px;
  border-bottom: 1px solid #dadada;
}

.back-wrapper {
  max-width: 1366px;
  margin: 0 auto;
  padding: 0 16px;
}

.back-to-overview {
  display: flex;
  cursor: pointer;
  align-items: center;
  font-size: 20px;
  gap: 10px;
  padding: 10px 0;
}

.back-wrapper span {
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-weight: 700;
  color: var(--secondary);
}

.back-wrapper span:hover {
  text-decoration: underline;
}

.download-pdf {
  font-size: 14px;
  font-weight: 600;
  color: var(--secondary);
  background-color: var(--primary);
  padding: 12px 16px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
}

@media (max-width: 768px) {
  .resources-content-container {
    padding: 80px 16px;
  }
  .resources-wrapper h1 {
    text-align: center;
  }
}

/* ABOUT US */

.about-us-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background-color: var(--secondary);
  padding-top: 64px;
}

.text-container {
  display: flex;
  align-items: center;
  width: 968px;
  text-align: left;
  margin: 0 32px;
}

.about-us-text-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 80px 32px;
}

.about-us-text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 968px;
}

.about-us-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  list-style: disc;
  margin-left: 30px;
  margin-bottom: 32px;
}

.about-us-staff-container {
  background-color: var(--primary);
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.ceo-heading-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.ceo-heading-container h2 {
  margin: 0;
}

.ceo-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.about-us-staff-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  padding: 50px;
  max-width: 822px;
}

.about-us-staff-text-container p {
  font-size: clamp(20px, 1vw, 24px);
}

.about-us-staff-image {
  clip-path: polygon(20% 0%, 100% 0, 100% 100%, 0% 100%);
  right: 0;
}

.whole-team-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.whole-team-container img {
  width: 90%;
  max-width: 1600px;
  /* margin-bottom: -2px; */
}

.about-us-tabs-container {
  z-index: 1;
  position: relative;
  /* background-color: var(--secondary); */
}

.about-us-tab-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 40px;
  background-color: var(--secondary-light);
}

.about-us-tab-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 968px;
}

.about-us-tab-element {
  margin: 50px 0;
}

.about-us-tabs {
  display: flex;
  justify-content: space-around;
}

.about-us-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 33.3%;
  height: 122px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  padding: 0 10px;
  background-color: var(--secondary);
}

.about-us-tab:hover {
  background-color: var(--secondary-hover);
  transition: background-color 0.3s ease;
}

.about-us-active-tab {
  background-color: var(--secondary-light);
}

.about-us-active-tab:hover {
  background-color: var(--secondary-light);
}

.about-us-family-container {
  display: flex;
  justify-content: center;
  background-color: var(--secondary);
  margin-top: -1px;
  padding: 32px 0;
}

.family-text-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  max-width: 968px;
  padding: 32px;
}

.family-text-wrapper p {
  font-weight: 400;
}

@media screen and (max-width: 1500px) {
  .about-us-staff-image {
    margin-right: -150px;
  }
}

@media screen and (max-width: 1200px) {
  .about-us-staff-container {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    margin-top: 100px;
    overflow: visible;
  }
  .about-us-staff-image {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
    right: 0;
    margin-top: -100px;
    width: 80%;
    margin-right: 0;
    border-radius: 12px;
    box-shadow: 0 2px 6px 2px rgba(9, 30, 66, 0.14);
  }
  .upside-down-right-to-left-triangle-white-about {
    display: none;
  }
  .ceo-image-container {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .about-us-text-container,
  .family-text-wrapper {
    padding: 64px 16px;
  }
  .about-us-tab-content-container {
    padding: 0 16px;
  }
  .text-container {
    text-align: center;
    justify-content: center;
  }
  .careers-header-container h1 {
    text-align: center;
  }
}

@media (max-width: 500px) {
  .about-us-tab h2 {
    font-size: 20px;
  }
}

/* TEAM CARD */

.team-lead-card-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 64px 48px;
  gap: 60px;
}

.team-lead-card-wrapper {
  display: flex;
  gap: 20px;
}

.team-lead-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray);
  gap: 20px;
  justify-content: space-between;
  border-radius: 12px;
  overflow: hidden;
  max-width: 420px;
  max-height: 600px;
}

.team-lead-details {
  text-align: center;
  padding: 32px 16px;
}

.team-lead-details h2 {
  font-weight: 400;
}

.team-lead-details p {
  color: var(--secondary);
}

.team-lead-image-container img {
  height: 100%;
  width: 100%;
}

@media (max-width: 900px) {
  .team-lead-card-container {
    gap: 64px;
  }
  .team-lead-card-wrapper {
    flex-direction: column;
  }
  .team-lead-card {
    flex-direction: row;
    max-height: 200px;
    max-width: 100%;
  }
  .team-lead-image-container {
    max-width: 40%;
  }
  .team-lead-details {
    display: flex;
    text-align: left;
    flex-direction: column;
    justify-content: center;
  }
  .team-lead-details h3 {
    font-weight: 400;
    font-size: 24px;
  }
}

@media (max-width: 480px) {
  .team-lead-image-container {
    max-width: 30%;
    margin-left: 65%;
    min-width: 150px;
    max-height: 180px;
  }
  .team-lead-image-container img {
    height: 100%;
    margin-top: 10px;
  }
  .team-lead-details {
    position: absolute;
  }
}
@media (max-width: 360px) {
  .team-lead-image-container {
    margin-left: 55%;
  }
}

/* CONTACT */

.contact-image-container {
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding-top: 64px;
  height: 400px;
}

.innaxys-on-map {
  object-fit: cover;
  width: 100%;
}

.contact-form-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 40px 0px;
}

.chips {
  display: flex;
  justify-content: center;
}

.contact-form-header {
  width: 100%;
  max-width: 1366px;
  padding: 80px 40px 20px;
}

@media (max-width: 600px) {
  .contact-form-container {
    padding: 0 16px 0;
  }

  .contact-form-header {
    padding: 40px 16px 20px;
  }
}

.contact-form-wrapper {
  width: 1366px;
}

/* CAREERS */

.careers-header-container {
  width: 1366px;
  padding: 0 64px;
}

.careers-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.careers-wrapper {
  display: flex;
  flex-direction: column;
  gap: 64px;
  max-width: 1366px;
  padding: 80px 64px;
}

.careers-wrapper div {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

/* .careers-subheading {
  margin: 40px 0;
} */

.interest-section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--gray);
  padding-bottom: 64px;
}

.interest-section-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 64px;
}

.interest-section-container p {
  max-width: 900px;
  text-align: center;
}

.book-demo-form-container p {
  text-align: left;
}

@media (max-width: 768px) {
  .careers-wrapper {
    padding: 80px 16px 52px; 
  }
  .careers-header-container {
    padding: 0 16px;
  }

  .interest-section-wrapper {
    padding: 24px 16px;
  }
}

/* VACANCIES */

.vacancies-container {
  padding: 0 32px;
  margin-bottom: 120px;
  display: flex;
  justify-content: center;
}

.vacancies-container h1 {
  margin-bottom: 30px;
}

.vacancies-wrapper {
  max-width: 1366px;
  width: 100%;
}

.vacancies-card-section-container {
  display: flex;
  flex-direction: column;
}

.vacancies-card-section-wrapper {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.vacancies-card-section-container:has(div) .no-openings-container  {
  display: none;
}

.no-openings-container {
  text-align: center;
  background-color: #f4f4f5;
  border-radius: 24px;
  padding: 32px 24px;
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-weight: 700;
  border: 1px solid var(--gray);
  color: var(--secondary);
}

.vacancy-card-container {
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: var(--gray);
  border-radius: 16px;
  /* border: 1px solid var(--gray); */
  cursor: pointer;
}

.vacancy-card-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.vacancy-card-wrapper i {
  margin: 0 20px;
}

.vacancy-card-title {
  margin: 0;
  color: var(--secondary);
  font-size: 40px;
}

.vacancy-details-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.vacancy-card-info-wrapper {
  display: flex;
  gap: 40px;
  color: var(--secondary);
}

.vacancy-card-area,
.vacancy-card-date {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 24px;
  font-family: "Montserrat", "Geist Sans", "Roboto";
}

.vacancy-description {
  display: none;
  padding-top: 25px;
}

.vacancy-description div {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.vacancy-description span {
  font-weight: 700;
  font-size: 24px;
  color: var(--secondary);
}

.vacancy-description ul {
  list-style-type: disc;
}

.vacancy-description li {
  margin: 10px 25px;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

@media screen and (max-width: 768px) {
  .vacancy-card-container {
    padding: 16px;
  }
  .vacancy-card-info-wrapper {
    flex-direction: column;
    gap: 10px;
  }

  .vacancies-container {
    padding: 28px 16px;
  }
}

@media (max-width: 500px) {
  .vacancy-details-wrapper h2 {
    font-size: 20px;
  }
  .vacancy-details-wrapper span {
    font-size: 16px;
  }
}

/* FORMS */

.careers-form-header-container {
  text-align: center;
  padding: 20px;
}

.careers-form-header-container span {
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-size: 24px;
  font-weight: 700;
  color: var(--secondary);
  border-bottom: 4px solid var(--primary);
}

.form-container {
  background-color: var(--white);
  width: 100%;
  padding: 0 24px;
  border-radius: 16px;
  box-shadow: 0 2px 6px 2px rgba(9, 30, 66, 0.14);
}

.book-demo-form-container {
  background-color: var(--white);
  max-width: 800px;
  width: 80%;
  padding: 0 24px;
  border-radius: 16px;
  box-shadow: 0 2px 6px 2px rgba(9, 30, 66, 0.14);
}

.form-input-container {
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 24px;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .form-input-container {
    flex-direction: column;
    gap: 0;
  }
}

.singular-input-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.form-input-container-full {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.form-input-container-full span,
.form-resume-container span {
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-weight: 400;
}

.form-resume-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: flex-start;
}

.resume-input {
  border: none;
  padding: 0 0 4px 0;
}

input::file-selector-button {
  width: fit-content;
  background: none;
  border: 1px solid var(--secondary);
  color: var(--secondary);
  font-size: 16px;
  font-weight: 700;
  padding: 12px 20px;
  border-radius: 8px;
  margin: 2px 10px 10px 0;
  cursor: pointer;
}

input::file-selector-button:hover {
  background-color: var(--gray);
  transition: background-color 0.3s ease;
}

.resume-guideline {
  font-size: 16px;
}

.Z {
  color: red;
}

/* CAREERS FORM */

.careers-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 968px;
  text-align: center;
  padding: 32px;
}

.careers-form-section-subheading {
  margin: 20px 0 30px;
}

.career-form-container {
  text-align: center;
  background-color: var(--white);
  width: 904px;
  padding: 24px;
  border-radius: 16px;
  box-shadow: 0 2px 6px 2px rgba(9, 30, 66, 0.14);
  margin-bottom: 20px;
}

.careers-form-header {
  border-radius: 16px 16px 0 0;
  width: 100%;
  padding: 24px 0;
  text-align: center;
}

.careers-form-title {
  display: inline-flex;
  color: var(--secondary);
  border-bottom: 4px solid var(--primary);
}

/* TABLE */

.table-container {
  width: 100%;
  margin-bottom: 40px;
}

.table-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-bottom: 1px solid var(--secondary);
  color: var(--secondary);
  font-size: 24px;
  font-weight: 600;
}

.table-row {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  font-size: 24px;
  font-weight: 400;
  border-bottom: 1px solid rgba(40, 50, 68, 0.336);
}

.table-footer {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  color: var(--secondary);
  font-size: 24px;
  font-weight: 600;
  border-top: 1px solid var(--secondary);
  border-bottom: 1px solid var(--secondary);
}

/* FOOTER CERTIFICATES */

.footer-certificate-container {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  /* border-top: 1px solid var(--gray); */
  flex-wrap: wrap;
}

.footer-certificate-logo {
  height: 95px;
  margin: 40px;
}

@media (max-width: 500px) {
  .footer-certificate-logo {
    /* height: 60px; */
    margin: 30px 15px;
  }
}

/* FOOTER */

.footer-logo {
  margin-bottom: 20px;
}

.footer-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--secondary);
  padding: 32px;
  color: white;
  z-index: 100;
  bottom: 0;
}

.footer-options-wrapper {
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 14px;
  max-width: 1300px;
  width: 100%;
}

.innaxys-footer-options p {
  opacity: 70%;
  font-size: 16px;
  /* margin: 10px 0; */
  color: var(--gray);
  line-height: 24px;
}



.footer-text-divider {
  margin: 10px 0;
}

.footer-header {
  font-size: 16px;
  font-weight: 700;
  margin: 10px 0;
  color: var(--gray);
}

.footer-options-right {
  display: flex;
  gap: 40px;
}

.footer-choices {
  max-width: 260px;
}

.footer-choices li {
  line-height: 20px;
}

.footer-choices ul {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.footer-choices a {
  color: white;
  opacity: 70%;
  font-weight: 400;
  font-size: 16px;
}

.footer-choices a:hover {
  text-decoration: underline;
}

.footer-extra {
  display: grid;
  place-items: center;
  margin-top: 50px;
}

.social-icons {
  cursor: pointer;
  color: white;
  width: 30px;
}

.footer-socials {
  display: flex;
  justify-content: center;
  width: 80px;
  margin-bottom: 20px;
}

.footer-terms {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footer-divider {
  background-color: #fff;
  opacity: 70%;
  font-weight: 400;
  height: 15px;
  width: 2px;
  margin: 0 10px;
}

.footer-term {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 16px;
  opacity: 70%;
  font-weight: 400;
}

.footer-term-no-access {
  display: flex;
  justify-content: center;
  color: white;
  font-size: 16px;
  opacity: 70%;
  font-weight: 400;
  cursor: default;
}

@media (max-width: 768px) {
  .innaxys-footer-options p {
    font-size: 14px;
  }

  .footer-choices a {
    font-size: 14px;
  }

  .footer-term {
    font-size: 14px;
  }
  .footer-term-no-access {
    font-size: 14px;
  }
}

.footer-term:hover {
  text-decoration: underline;
}

@media (max-width: 900px) {
  .footer-options-wrapper {
    display: grid;
    grid-template-columns: 2fr 2fr;
  }
}

@media (max-width: 800px) {
  .footer-options-wrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;
  }
}

@media (max-width: 500px) {
  .footer-options-right {
    flex-direction: column;
    margin-top: 40px;
  }
  .footer-divider {
    height: 1px;
    width: 20px;
    margin: 10px;
  }
  .footer-terms {
    flex-direction: column;
  }
}

/* MEET US */

.meet-us-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  gap: 48px;
  background-color: #ff9330;
  padding-top: 96px;
  /* filter: drop-shadow(0px 4px 6px #000000); */
  z-index: 10;
  position: relative;
}

.meet-us-container span {
  font-family: "Montserrat", "Geist Sans", "Roboto";
  font-size: 24px;
  font-weight: 700;
}

@media (max-width: 500px) {
  .meet-us-container {
    flex-direction: column;
    text-align: center;
    gap: 20px;
    padding: 16px;
    padding-top: 80px;
  }
  .meet-us-container span {
    font-size: 18px;
  }
}

/* TRIANGLES */

/* STANDARD GRAY */

.right-to-left-triangle {
  /* position: absolute; */
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: var(--gray);
  margin-top: -40px;
}

/* .wraps {
  filter: drop-shadow(0px 0px 10px #000000);
} */

.left-to-right-triangle {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background-color: var(--gray);
  margin-top: -39.7px;
}

.upside-down-right-to-left-triangle {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: var(--gray);
  margin-bottom: -40px;
}

.upside-down-left-to-right-triangle {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  background-color: var(--gray);
  margin-bottom: -40px;
}

/* GREEN */

.right-to-left-triangle-green {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: var(--primary);
  margin-top: -40px;
}

.upside-down-right-to-left-triangle-green {
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: var(--primary);
  margin-top: -1px;
}

.left-to-right-triangle-green {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background-color: var(--primary);
  margin-top: -40px;
}

.upside-down-left-to-right-triangle-green {
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  background-color: var(--primary);
  margin-top: -1px;
}

/* WHITE */

.right-to-left-triangle-white {
  /* position: absolute; */
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: var(--white);
  margin-top: -40px;
}

.left-to-right-triangle-white {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background-color: var(--white);
  margin-top: -40px;
}

.right-to-left-triangle-white-contact {
  position: absolute;
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: var(--white);
  margin-top: -40px;
}
.right-to-left-triangle-white {
  /* position: absolute; */
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0% 100%, 100% 100%);
  background-color: var(--white);
  margin-top: -40px;
}

.upside-down-right-to-left-triangle-white {
  z-index: 20;
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: var(--white);
  margin-top: -1px;
}
.upside-down-right-to-left-triangle-white-about {
  z-index: 20;
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: var(--white);
  margin-top: -1px;
  position: absolute;
}

/* DARK BLUE */

.upside-down-right-to-left-triangle-secondary {
  width: 100%;
  height: 40px;
  clip-path: polygon(100% 0, 0 0, 100% 100%);
  background-color: var(--secondary);
  margin-top: -0.3px;
}

.upside-down-left-to-right-triangle-secondary {
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 0);
  background-color: var(--secondary);
  margin-top: -1px;
}

.triangle-divider {
  background-color: var(--secondary);
  height: 80px;
}

.progress-bar {
  z-index: 99;
  width: 100%;
  /* position: fixed; */
  /* margin-top: 64px; */
}

.sticky {
  position: fixed;
  top: 64px;
  width: 100%;
}

/* .header {
  margin-top: 64px;
} */

.features-container-new {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  gap: 40px;
}

.product-feature-wrapper-new {
  background-color: #fff;
  width: 450px;
  height: 350px;
  padding: 16px;
  overflow: hidden;
}

.feature-context-new {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.feature-icon-container-new {
  height: 60%;
  overflow: hidden;
}

.test-img {
  width: 100%;
}

.feature-context-new p {
  max-width: 300px;
  font-size: 16px;
}

.feature-icon {
  color: var(--primary);
  margin-left: 300px;
  margin-top: -100px;
}

.product-feature-wrapper-new2 {
  background-color: #fff;
  width: 250px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.icon-container-new2 {
  border-bottom: 1px solid black;
  padding: 32px 0;
}

.left-to-right-triangle-products {
  /* position: absolute; */
  width: 100%;
  height: 40px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
  background-color: var(--gray);
  /* margin-top: -40px; */
}


.no-click {
  cursor: not-allowed;
}

/* Icon 1 */

#nav-icon3 {
  width: 20px;
  height: 15px;
  position: relative;
  /* margin: 50px auto; */
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#nav-icon1 span, #nav-icon3 span, #nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: var(--secondary);
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

/* Icon 3 */

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),#nav-icon3 span:nth-child(3) {
  top: 6px;
}

#nav-icon3 span:nth-child(4) {
  top: 12px;
}

#nav-icon3.open span:nth-child(1) {
  top: 12px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
